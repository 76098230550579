import SeoTitle from "../../Title/SeoTitle";
import React from "react";
const WhereWeAre = () => {
  return (
    <>
      <section className="seo_fact_area sec_pad">
        <div className="home_bubble">
          <div className="bubble b_one"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four"></div>
          <div className="bubble b_six"></div>
          <div
            className="triangle b_eight"
            data-parallax='{"x": 120, "y": -10}'
          >
            <img src="img/seo/triangle_one.png" alt="" />
          </div>
        </div>
        <div className="container">
          <SeoTitle Title="Where we are" />
          <div className="seo_fact_info">
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter three" style={{ fontSize: "40px" }}>
                  11,000+
                </div>
                <p>Smallholder Farmers</p>
              </div>
            </div>
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter two">5 </div>
                <p>Regions</p>
              </div>
            </div>
            <div className="seo_fact_item">
              <div className="text">
                <div className="counter three">50+</div>
                <p>Aggregators</p>
              </div>
            </div>
            <div className="seo_fact_item last">
              <div className="text">
                <div className="counter four" style={{ fontSize: "35px" }}>5000 MT</div>
                <p>Grains Produced & Sold</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhereWeAre;
