import React from "react";

const AboutFeature = () => {
  return (
    <section className="tracking_product_area sec_pad">
      <div className="container">
        <TrackingProductItem
          TrackingTitle="About Us"
          Trackingd="Wami Agro is an AgTech company based in Ghana that delivers tech-enabled solutions to secure sustainable sourcing, improve farmer's quality of life and protect the environment. We typically work with actors in the production and market value chain.           "
          Trackingbtn="Learn more"
        />
        <TrackingProductItem
          itemClass="about-Feature_two"
          rowclass="aboutFlexReverse"
          TrackingTitle="Our Mission"
          Trackingd="To make wholesome food ingredients available, accessible and affordable to factories, FMCG companies and food distributors while ensuring fair trade."
          Trackingbtn="Learn more"
        />
        <TrackingProductItem
          TrackingTitle="Our Vision"
          Trackingd="To be a leading company driving sustainable food production and supply in Africa."
          Trackingbtn="Learn more"
        />
        <TrackingProductItem
          itemClass="about-Feature_two"
          TrackingTitle="Our Value Proposition"
          Trackingd="To make wholesome food ingredients available, accessible, and
          affordable to factories, FMCG companies, and food distributors while
          ensuring fair trade."
          Trackingbtn="Learn more"
        />
        <div className={``}>
          <div className={`about-Feature_list`}>
            <div className="">
              <div className="about-Feature_content">
                <div className="">
                  <h3>Our Values</h3>
                  <ul>
                    <li>Transparency</li>
                    <li>Respect</li>
                    <li>Continuous learning</li>
                    <li>Perseverance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFeature;

function TrackingProductItem({
  itemClass,
  TrackingTitle,
  Trackingd,
  rowclass,
}) {
  return (
    <div className={` ${itemClass}`}>
      <div className={`about-Feature ${rowclass}`}>
        <div className="">
          <div className="about-Feature_content">
            <div className="">
              <h3>{TrackingTitle}</h3>
              <p>{Trackingd}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
