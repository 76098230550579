import React from "react";
import Featureitem from "./FeatureItem";
import "../../../assets/feature.css";

const FeatureList = () => {
  return (
    <section className="erp_features_area_two">
      <div className="container">
        <Featureitem
          rowClass="align-items-center featureMargin"
          featureLink="featureLink"
          image="farmer-4.png"
          title="At Wamiagro"
          url="#"
          content="Our innovative model provides agricultural inputs via credit to groups of 
                smallholder farmers producing staple grains in agricultural co-operatives, 
                while accepting repayment on loaned goods through the purchase of produce from our network,
                 which are sold to large trade partners. This widens access to finance and guarantees market 
                 access while being complimented by a program of capacity building – including the provision of information, 
                education, soil testing and farm input prescription to our network of farmers to improve yields.
                "
        />
        <Featureitem
          rowClass="align-items-center flex-row-reverse featureMargin"
          featureSpace="featureSpace"
          featureLink="featureLink"
          image="farmer-5.png"
          title="Our Solutions"
          url="#"
          content="Our digital solution enables the following features:
          Input Credit - a lending platform and farm management system that enables the effective distribution of input credit in the form of mechanization, seeds, fertilizers and pesticides to smallholder farmers on a deferred payment term.
          Marketplace- B2B ecommerce platform for farmers and institutional off-takers.
          Wami Info - Voice Note Bulk Broadcasting service which delivers relevant real-time voice messages in local dialects to farmers."
        />
      </div>
    </section>
  );
};
export default FeatureList;
