import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import FooterTwo from '../components/Footer/FooterTwo';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import Header from '../components/Wami/Header';

const Service = () => {
    return(
        <div className="body_wrapper">
            <Header cClass="custom_container p0" hbtnClass="new_btn" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Product and Services" 
            Pdescription=""/>
            <HRService ServiceData={ServiceData}/>
            <FooterTwo fClass="pt_20" FooterData={FooterData}/>
        </div>
    )
}
export default Service;