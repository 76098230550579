
import React from "react"
import Sectitle from '../../Title/Sectitle'

const PukparaDetails = () => {
    return (
        <>
        <div className="container"> <div className="row"></div></div>
        <div className="container mt_100"> <div className="row"></div></div>
         <section className="design_tab_area sec_pad">
            <div className="container">
                <Sectitle sClass="sec_title text-center mb_70" Title='Pukpara Portal' 
                TitleP='A lending platform and farm management system that enables the effective 
                distribution of input credit in the form of mechanization, 
                seeds, fertilizers and pesticides to smallholder farmers on a deferred payment term.' />
                <div className="row">
                    <div className="col-lg-4 d-flex align-items-center">
                        <ul className="nav nav-tabs design_tab support_tab" role="tablist">
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.4s">
                                <a className="nav-link active" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="true">
                                    <h5>Farmer Data</h5>
                                    <p>Collation of farmer data<br /> with batch uploads</p>
                                </a>
                            </li>
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.6s">
                                <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">
                                    <h5>Input Credit</h5>
                                    <p>Distribution<br /> and tracking</p>
                                </a>
                            </li>
                            <li className="nav-item wow fadeInUp" data-wow-delay="0.8s">
                                <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="false">
                                    <h5>Savings and Loans</h5>
                                    <p>Manage credit and loans,<br /> with no troubles</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content support_tab_content">
                            <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                                <div className="tab_img">
                                    <img src={require("../../../img/pukpara/groups.png")} alt="" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div className="tab_img">
                                    <img src={require("../../../img/pukpara/orders.png")} alt="" />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                                <div className="tab_img">
                                    <img src={require("../../../img/pukpara/farmer.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </>
    )
}


export default PukparaDetails
