import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import Header from "../components/Wami/Header";
import AboutFeature from "../components/About/AboutFeature";

const About = () => {
  return (
    <div className="body_wrapper">
      <Header cClass="custom_container p0" hbtnClass="new_btn" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area "
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Who We Are"
        Pdescription=""
      />
      <AboutFeature />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
