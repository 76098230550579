import React, { Component } from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Portfolio2griditem from './Portfolio2griditem';

class MediaPage extends Component {
    state = {
        activeItem: '*',
    }

    componentDidMount() {
        var imgLoad = new ImagesLoaded('#work-portfolio');

        imgLoad.on('progress', function (instance, image) {
            this.iso = new Isotope('#work-portfolio', {
                itemSelector: '.portfolio_item',
                layoutMode: "masonry"
            });
        });

    }
    onFilterChange = (newFilter) => {

        this.setState({ activeItem: newFilter });
        if (this.iso === undefined) {
            this.iso = new Isotope('#work-portfolio', {
                itemSelector: '.portfolio_item',
                layoutMode: "masonry"
            });
        }

        // this.iso.arrange({ filter: newFilter });   
        if (newFilter === '*') {
            this.iso.arrange({ filter: `*` });
        } else {
            this.iso.arrange({ filter: `.${newFilter}` });
        }
    }

    onActive = v => v === this.state.activeItem ? 'active' : '';

    render() {
        return (
            <section className="portfolio_area sec_pad bg_color">
                <div className="container">
                    <div id="portfolio_filter" className="portfolio_filter mb_50">
                        <div data-filter="*" className={`work_portfolio_item ${this.onActive('*')}`} onClick={() => { this.onFilterChange("*") }}>All</div>
                        <div data-filter="field" className={`work_portfolio_item ${this.onActive('field')}`} onClick={() => { this.onFilterChange("field") }}>Field</div>
                        <div data-filter="people" className={`work_portfolio_item ${this.onActive('people')}`} onClick={() => { this.onFilterChange("people") }}>We Are A People</div>
                        <div data-filter="warehouse" className={`work_portfolio_item ${this.onActive('warehouse')}`} onClick={() => { this.onFilterChange("warehouse") }}>Warehousing</div>
                        <div data-filter="transit" className={`work_portfolio_item ${this.onActive('transit')}`} onClick={() => { this.onFilterChange("transit") }}>Supply</div>
                    </div>
                    <div className="row portfolio_gallery mb_30" id="work-portfolio">
                        <Portfolio2griditem colClass="col-lg-6 field " pImg="wami/02.jpg" ptitle="" tOne="Field Work" />
                        <Portfolio2griditem colClass="col-lg-6 people" pImg="wami/100.jpg" ptitle="" tOne="People" tTwo="We are go beyond just work, we are a corporate family" />
                        <Portfolio2griditem colClass="col-lg-6 field" pImg="wami/01.jpg" ptitle="Field Work" tOne="Web" tTwo="Field Work" />
                        <Portfolio2griditem colClass="col-lg-6 people" pImg="wami/102.jpg" ptitle="" tOne="Develop" tTwo="We are go beyond just work, we are a corporate family" />
                        <Portfolio2griditem colClass="col-lg-6 field" pImg="wami/03.jpg" ptitle="Portfolio Center Slider" tOne="Web" tTwo="Field Work" />
                        <Portfolio2griditem colClass="col-lg-6 warehouse" pImg="wami/201.jpg" ptitle="" tOne="People" tTwo="" />
                        <Portfolio2griditem colClass="col-lg-6 field" pImg="wami/05.jpg" ptitle="Portfolio Masonry" tOne="Field Work" />
                        <Portfolio2griditem colClass="col-lg-6 warehouse" pImg="wami/202.jpg" ptitle="" tOne="Store houses" tTwo="" />
                        <Portfolio2griditem colClass="col-lg-6 transit" pImg="wami/204.jpg" ptitle="" tOne="People" tTwo="" />
                        <Portfolio2griditem colClass="col-lg-6 people" pImg="wami/101.jpg" ptitle="" tOne="We are go beyond just work, we are a corporate family" />
                        <Portfolio2griditem colClass="col-lg-6 field" pImg="wami/06.jpg" ptitle="" tOne="Feild Work" tTwo="" />
                    </div>
                </div>
            </section>
        )
    }
}
export default MediaPage;