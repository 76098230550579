import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import MediaPage from '../components/Portfolios/MediaPage';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Header from '../components/Wami/Header';

const Media = () => {
    return(
        <div className="body_wrapper">
            <Header cClass="custom_container p0" hbtnClass="new_btn"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Our Journey Our Stories" 
            Pdescription=""/>
            <MediaPage/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Media;