import React from "react";
import "../../../assets/feature.css";


const CarouselText = () => {
    return (
        <>
            <div className="">
                <div className="">
                    <h1 className="wow fadeInUp textRectangle" data-wow-delay="0.2s">
                        <div>
                            Making wholesome food ingredients <strong>Available</strong>,{" "}
                            <strong>Accessible</strong> and <strong>Affordable.</strong>
                        </div>
                    </h1>
                </div>
            </div>
        </>
    )
}

export default CarouselText;