import React from "react";
import Featuresitems from "../Features/Featuresitems";

const ImpactWriteup = () => {
  return (
    <>
      <section className={`agency_featured_area bg_color`}>
        <div className="container">
          {/* <h2 className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp" data-wow-delay="0.3s">You SaasLand wherever your<br/> business agency</h2> */}
          <div className="features_info">
            <img
              className="dot_img"
              src={require("../../img/home4/dot.png")}
              alt=""
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="farmer1.jpg"
              iImg="icon01.png"
              ftitle="Improved well-being of farmers and their families"
              descriptions="Over the last 2 years, we’ve observed about a 40% revenue increase for our farmers and about a 70% reduction in post-harvest losses. This has increased their ability to access quality health care, and advanced education for the children and increased their overall purchasing power.                        "
            />

            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="farmer2.jpg"
              iImg="icon02.png"
              ftitle="Promotion of food security"
              descriptions="There is a deficit in local production in Africa to the extent that the continent imported $55 billion of food in 2022. Most processing companies have to resort to imports which are not their preference. Our solution seeks to provide our customers with shorter value chains while impacting vulnerable farmers at the bottom of the economic ladder."
            />

            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="farmer3.jpg"
              iImg="icon3.png"
              ftitle="Financial inclusion for smallholder farmers"
              descriptions="In Ghana, financial sector actors regard agriculture as high risk due to uncertainties in the industry such as fluctuating climate conditions, unstructured marketplaces, and unreliable supply chains, and hence lend money to agriculture companies at high-interest rates that are expensive for farmers."
              descriptions2="Our cost of capital is lower than the average financing cost of traditional financial institutions. Farmers repay our investment in kind (produce), and an additional 20% of the input value is offered also in kind. Our proprietary platform enables each farmer to have a financial record of their past transactions which can be used by other financial institutions for credit scoring."
            />
            <div className="dot middle_dot">
              <span className="dot1"></span>
              <span className="dot2"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImpactWriteup;
