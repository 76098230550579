import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-stickynode";

class Header extends Component {
  render() {
    var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;
    return (
      <Sticky top={0} className="navbar_start" innerZ={9999}  activeClass="navbar_fixed">
        <header className="header_area ">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Link className={`navbar-brand ${slogo}`} to="/">
                <img src={require("../../img/wami-logo.png")} alt="" />
                <img src={require("../../img/wami-logo.png")} alt="logo" />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item">
                    <Link title="Home" className="nav-link" to="/">
                      <span className="text-shadow">Home</span>
                    </Link>
                  </li>
                  <li className="nav-item dropdown submenu">
                    <a
                      className="nav-link dropdown-toggle"
                      href=".#"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      About Us
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink to="/about" className="nav-link">
                          Who we are
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink to="/team" className="nav-link">
                          Our Team
                        </NavLink>
                      </li> */}
                      <li className="nav-item">
                        <NavLink to="/model" className="nav-link">
                          Our Model
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/impact" className="nav-link">
                          Social and Enviromental Impact
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown submenu nav-item">
                    <Link
                      to="./"
                      title="Pages"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Services
                    </Link>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Service"
                          className="nav-link"
                          to="/service"
                        >
                          What We Do
                        </NavLink>
                      </li>
                      {/* <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/ServiceDetails'>Market Place</NavLink></li> */}
                      <li className="nav-item">
                        <NavLink
                          exact
                          title="Farmer Management"
                          className="nav-link"
                          to="/pukpara"
                        >
                          Farmer Management
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="dropdown submenu nav-item">
                    <Link
                      title="Portfolio"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      to="#"
                    >
                      Portfolio
                    </Link>
                    <ul role="menu" className=" dropdown-menu">
                      {/* <li className="nav-item"><NavLink title="Portfolio 2" className="nav-link" to='/Portfolio-2col'>The Jorney</NavLink></li> */}
                      <li className="nav-item">
                        <NavLink title="Media" className="nav-link" to="/media">
                          Media
                        </NavLink>
                      </li>
                      {/* <li className="nav-item"><NavLink title="Portfolio Fullwidth" className="nav-link" to='/Portfolio-fullwidth-4col'>Blog</NavLink></li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <NavLink title="Contact" className="nav-link" to="/Contact">
                      <span>Contact</span>
                    </NavLink>
                  </li>
                </ul>
                <a
                  className={`btn_get btn_hover`}
                  href="https://market.wamiagro.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Marketplace
                </a>
                <a
                  className={`btn_get btn_hover ${hbtnClass}`}
                  href="https://pukpara.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Login/Signup
                </a>
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default Header;
