import React, { Component } from "react";
import AboutWidget from "./FooterWidget/AboutWidget";
import SolutionWidget from "./FooterWidget/SolutionWidget";
import TeamWidget from "./FooterWidget/TeamWidget";
import Reveal from "react-reveal/Reveal";

class FooterTwo extends Component {
  render() {
    var { fClass } = this.props;
    let FooterData = this.props.FooterData;
    return (
      <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
        <div className="footer_top">
          <div className="container">
            <div className="row">
              {FooterData.CompanyWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInUp" key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget company_widget wow fadeInLeft"
                        data-wow-delay="0.2s"
                      >
                        <a href="index.html" className="f-logo">
                          <img
                            src={require("../../img/wami-logo.png")}
                            alt=""
                          />
                        </a>
                        <div className="widget-wrap">
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Email:</span>{" "}
                            <a
                              href="mailto:hell@wamiagro.com"
                              className="f_400"
                            >
                              hello@wamiagro.com
                            </a>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Phone:</span>{" "}
                            <a href="tel:+233548416523" className="f_400">
                              +233 548 416 523
                            </a>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Address:</span>{" "}
                            <a
                              href="https://maps.app.goo.gl/HpPUKZBM3A7zoD4g7"
                              target="_blank"
                              className="f_400"
                              rel="noopener noreferrer"
                            >
                              2 Mahogany Hwy, Accra
                            </a>
                          </p>
                        </div>

                        <div className="f_social_icon widget-wrap ">
                          {FooterData.socialIcon.map((item) => {
                            return (
                              <a
                                href={item.url}
                                rel="noopener noreferrer"
                                target="_blank"
                                key={item.id}
                              >
                                <i className={item.icon}></i>
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              <AboutWidget ftitle="About Us" FooterData={FooterData} />
              <SolutionWidget
                ftitle="Quick links"
                // ftitle="Workflow Solutions"
                FooterData={FooterData}
              />
              <TeamWidget ftitle="Team Solutions" FooterData={FooterData} />
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-sm-6">
                <p className="mb-0 f_400">© Wami {new Date().getFullYear()}</p>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6">
                <div className="f_social_icon_foot text-center">
                  {/* {
                                        FooterData.socialIcon.map(item => {
                                            return (
                                                <a href={item.url} key={item.id}><i className={item.icon}></i></a>
                                            )
                                        })
                                    } */}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <ul className="list-unstyled f_menu text-right">
                  <li>
                    <a href=".#">Terms of Use</a>
                  </li>
                  <li>
                    <a href=".#">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default FooterTwo;
