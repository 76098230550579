import React from "react";
import FeatureList from "./FeatureList";
import Header from "../Header";
import Breadcrumb from "../../Breadcrumb";

const OurModelDetails = () => {
  return (
    <div className="body_wrapper">
      <Header cClass="custom_container p0" hbtnClass="new_btn" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area "
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Our Model"
        Pdescription=""
      />
      <FeatureList />
    </div>
  );
};

export default OurModelDetails;
