import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Teams from '../components/Team/Team';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Header from '../components/Wami/Header';
const Team = () => {
    return (
        <div className="body_wrapper">
            <Header cClass="custom_container p0" hbtnClass="new_btn" />
            <Breadcrumb breadcrumbClass="breadcrumb_area " imgName="breadcrumb/banner_bg.png"
                Ptitle="Our Team"
                Pdescription="" />
            <Teams />
            <FooterTwo FooterData={FooterData} />
        </div>
    )
}
export default Team;