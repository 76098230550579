import React, { Component } from "react";
import "../../../assets/feature.css";
class Featureitem extends Component {
  render() {
    let { rowClass, featureSpace, featureLink, url, image, title, content } =
      this.props;
    return (
      <div className={`row mt_40 ${rowClass}`}>
        <div className="col-lg-6">
          <div className={`${featureSpace}`}>
            <img src={require("../../../img/wami-home/" + image)} alt="" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="">
            <div className="hosting_title erp_title">
              <h2>{title}</h2>
              <p style={{ textAlign: 'justify' }}>{content}</p>
            </div>
            <a href={`/${url}`} className={`erp_btn_learn ${featureLink}`}>
              Learn More<i className="arrow_right"></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Featureitem;
