import React from "react";
import Carousel from 'react-bootstrap/Carousel'

import Slide1 from "../../../img/wami-home/csl1.jpg";
import Slide2 from "../../../img/wami-home/csl2.jpg";
import Slide3 from "../../../img/wami-home/csl3.jpg";
//import Slide4 from "../../../img/wami-home/csl4.jpg";


const CarouselBanner = () => {
    return (
        <div id="home">
            <Carousel controls={false} slide={false}  data-bs-theme="dark" interval={5000}  pause={false}>
                <Carousel.Item>
                    <img className="d-block w-100 custom-img " src={Slide1} alt="First slide"/>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 custom-img "
                        src={Slide2}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 custom-img "
                        src={Slide3}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default CarouselBanner