import React from "react";
import { Parallax } from "react-parallax";
// import CarouselBanner from "./CarouselBanner";
import img1 from "../../../img/wami-home/bg1.jpg";
// import img2 from "../../../img/wami-home/bg2.jpg";
// import img3 from "../../../img/wami-home/bg3.jpg";
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
const insideStyles = {
  background: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  padding: "50px",
  top: "60%",
  left: "30%",
  borderRadius: "10px",
  transform: "translate(-50%,-50%)",
  color: "white",
  fontSize: "40px",
  fontWeight: "bold"
};


  
const HomeImagesPage = () => (
  <div style={styles}>
    
    <Parallax bgImage={img1} >
      <div style={{ height: 600 }}>
        <div style={insideStyles}>Field to Market: Your Agri-Partner in Growth.</div>
      </div>
    </Parallax>
    
  </div>
);

export default HomeImagesPage;