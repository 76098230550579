import React, { } from "react";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";

//! validation schema for the form inputs
const userSchema = Yup.object().shape({
  name: string().required("Name is required!"),
  email: Yup.string()
    .email("Invalid email!")
    .required("The email field is required!"),
  subject: string().nullable(),
  message: string().required("Message is required!"),
});

const Contacts = () => {
  //! Initialize the form methods using React Hook Form
  const methods = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const {
    register,
    formState: { errors },
  } = methods;

  //! Handle form submission
  const onSubmit = async (data) => {
    const dataToSubmit = { ...data };

    console.log(dataToSubmit);

    try {
      const response = await axios.post(
        "https://wamiagroapi-dev.us-east-1.elasticbeanstalk.com/api/website/contactus",
        dataToSubmit
      );
      if (response.status === 200) {
        //! Display a success message
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your message has been successfully sent!",
          timer: 3000,
        });
        console.log("Form submitted successfully");

        //! Clear the form
        methods.reset();
      }
    } catch (error) {
      //! Display an error message
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "There was an error. Please try again.",
        timer: 3000,
      });
      console.error("Error signing in:", error.message);
    }
  };

  return (
    <section className="contact_info_area sec_pad bg_color">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                Office Address
              </h6>
              <p className="f_400 f_size_15">
                No. 2 Mahogany HWY, Spintex - Kasapreko Bus Stop, Accra.
                <br></br> GT-334-6194.
              </p>
            </div>
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">
                Contact Info
              </h6>
              <p className="f_400 f_size_15">
                <span className="f_400 t_color3">Phone:</span>{" "}
                <a href="tel:0548416523">(+233) 548 416 523</a>
              </p>
              <p className="f_400 f_size_15">
                <span className="f_400 t_color3">Email:</span>{" "}
                <a href="mailto:hello@wamigro.com">hello@wamigro.com</a>
              </p>
            </div>
          </div>
          <div className="contact_form col-lg-9">
            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">
              Leave a Message
            </h2>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="contact_form_box"
                method="post"
                id="contactForm"
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        id="name"
                        {...register("name")}
                        placeholder="Your Name"
                      />
                      {errors.name && (
                        <p style={{ color: "red" }}>{errors.name.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        {...register("email")}
                        id="email"
                        placeholder="Your Email"
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        type="text"
                        id="subject"
                        {...register("subject")}
                        placeholder="Subject"
                      />
                      {errors.subject && (
                        <p style={{ color: "red" }}>{errors.subject.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea
                        {...register("message")}
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Enter Your Message . . ."
                      ></textarea>
                      {errors.message && (
                        <p style={{ color: "red" }}>{errors.message.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn_three">
                  Send Message
                </button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
