import React, { Component } from "react";

class Partner extends Component {
  render() {
    var { pClass, pClasst } = this.props;
    return (
      <>
        <section className={`${pClass}`}>
          <div className="container">
            <div className={`partner_logo_area_four ${pClasst}`}></div>
          </div>
        </section>

        <section className={`${pClass}`}>
          <div className="container">
            <div className={`partner_logo_area_four ${pClasst}`}>
              <h4 className="f_size_18 f_400 f_p text-center l_height28 mb_70">
                Partners
              </h4>
              <div className="row partner_info">
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.1s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/wangara.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.4s">
                  <a href=".#">
                    <img src={require("../img/wami-partners/rdf.jpg")} alt="" />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.6s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/absa.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${pClass}`}>
          <div className="container">
            <div className={`partner_logo_area_four ${pClasst}`}>
              <h4 className="f_size_18 f_400 f_p text-center l_height28 mb_70">
                Accelerators
              </h4>
              <div className="row partner_info">
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.1s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/unicorn.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.2s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/growthafrica.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.3s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/innohub.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.4s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/cala.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.5s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/mest.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.6s">
                  <a href=".#">
                    <img
                      src={require("../img/wami-partners/millercenter.png")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="logo_item wow fadeInLeft" data-wow-delay="0.7s">
                  <a href=".#">
                    <img src={require("../img/wami-partners/boa.png")} alt="" />
                  </a>
                </div>

              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Partner;
