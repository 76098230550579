import React from 'react';
import ImpactPageDetails from './ImpactPageDetails';
import Header from './Header';
import FooterData from '../../components/Footer/FooterData';
import Breadcrumb from '../Breadcrumb';
import FooterTwo from '../Footer/FooterTwo';
import ImpactWriteup from './ImpactWriteup';


const ImpactPage =()=>
{
    return (
        <>
            <Header cClass="custom_container p0" hbtnClass="new_btn" />
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Social and Environmental Impact" />

            <ImpactPageDetails/>
            <ImpactWriteup />
            <FooterTwo fClass="pt_20" FooterData={FooterData}/>

        </>
         )
}


export default ImpactPage;