import React from "react";
import Sectitle from "../Title/Sectitle";
import Teamitem from "../Team/Teamitem";
const Team = () => {
  return (
    <section className="experts_team_area sec_pad">
      <div className="container">
        <Sectitle
          sClass="text-center mb_70"
          Title=""
          tClass="t_color3"
          TitleP="We've gained considerable industry knowledge and experience over 
                the years of supporting smallholder farmers. Our team has a collective experience of 50 years in business management,
                  agribusiness and tech development."
        />
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Caleb Edwards"
              memberd="Co-Founder & CEO"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Jonah Jones Tuokpier"
              memberd="Co-Founder & COO"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Kwaku Anim-Asiedu"
              memberd="Supply Chain Manager"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Marrilyn Addison"
              memberd="Accountant"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Hatsu Raphael"
              memberd="Farmer Accounts Manager"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Portia Nyarko"
              memberd="Head of Admin & Corporate Affairs"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="profile.png"
              memberN="Priscilla Offei-Asare"
              memberd="Product Manager and Analyst"
            />
          </div>
          {/* <div className="col-lg-3 col-sm-6">
                        <Teamitem teamImage="profile.png" memberN="Robert Sarfo" memberd="Full-Stack Developer"/>
                    </div> */}
        </div>
      </div>
    </section>
  );
};
export default Team;
