import React from "react";
import Header from "./Header";
import OurModelDetails from "./Components/OurModelDetails";
import FooterTwo from "../Footer/FooterTwo";
import FooterData from "../Footer/FooterData";

const OurModel = () => {
  return (
    <>
      <Header cClass="custom_container p0" hbtnClass="new_btn" />
      <OurModelDetails />
      <FooterTwo FooterData={FooterData} />
    </>
  );
};

export default OurModel;
