import React from 'react'
import PukparaDetails from './Components/PukparaDetails';
import Header from './Header';
import FooterTwo from '../Footer/FooterTwo';
import FooterData from '../Footer/FooterData';


const PukparaPage = ()=>{
    return (
        <>
        <Header cClass="custom_container p0" hbtnClass="new_btn"/>
        <PukparaDetails />
        <FooterTwo FooterData={FooterData}/>
        
        </>
    )
}

export default PukparaPage;