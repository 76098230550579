import React from "react";
import Header from "../components/Wami/Header";
import FeatureList from "../components/Wami/Components/FeatureList";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import Partner from "../components/Partner";
import WhereWeAre from "../components/Wami/Components/WhereWeAre";

import ParrallaxBanner from "../components/Wami/Components/ParallaxBanner";
import CarouselBanner from "../components/Wami/Components/CarouselBanner";
import CarouselText from "../components/Wami/Components/CarouselText";
const wamihome = () => {
    return (
        <>
            <div>
                <Header cClass="custom_container p0" hbtnClass="" />
                {/* <HomeBanner /> */}
                <CarouselBanner />
                <ParrallaxBanner />
                <CarouselText />
                <FeatureList />
                <WhereWeAre />
                <Partner pClass="partner_logo_area bg_color" />

                <FooterTwo FooterData={FooterData} />

            </div>
        </>
    )
}

export default wamihome;