import React from "react";

const ImpactPageDetails = () => {
  return (
    <React.Fragment>
      <section className="get_started_three sec_pad">
        <div className="container">
          <div className="sec_title text-center mb_70">
            <h2 className="f_p f_size_30 l_height45 f_600 t_color3">
              Social and Environmental
              <br />
              Impact
            </h2>
          </div>

          <div className="row get_btn_info">
            <div className="col-md-6">
              <div className="get_btn_item">
                <div className="get_icon icon_two">
                  <i className="icon_group"></i>
                </div>
                <h3>
                  Wami initiatives is over the period addressing societal issues
                  and improving livelihoods
                </h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="get_btn_item">
                <div className="get_icon icon_two">
                  <i className="icon_lightbulb_alt"></i>
                </div>
                <h3>Promoting sustainable and conservative farming</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="intregration_area bg_color sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="row intregration_logo">
                <div className="col-6 intregration_item">
                  <a href=".#" className="intregration_icon">
                    <img
                      src={require("../../img/sdgs/01.png")}
                      alt=""
                      style={{ width: "150px", height: "150" }}
                    />
                  </a>
                </div>
                <div className="col-6 intregration_item">
                  <a href=".#" className="intregration_icon">
                    <img
                      src={require("../../img/sdgs/02.png")}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </a>
                </div>
                <div className="col-6 intregration_item">
                  <a href=".#" className="intregration_icon">
                    <img
                      src={require("../../img/sdgs/05.png")}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </a>
                </div>
                <div className="col-6 intregration_item">
                  <a href=".#" className="intregration_icon">
                    <img
                      src={require("../../img/sdgs/08.png")}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </a>
                </div>{" "}
                <div className="col-6 intregration_item">
                  <a href=".#" className="intregration_icon">
                    <img
                      src={require("../../img/sdgs/13.png")}
                      alt=""
                      style={{ width: "150px", height: "150px" }}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-flex">
              <div className={`intregration_content sdgContent`}>
                {/* <h2>Social and Environmental Impact</h2> */}
                <p>
                  <ul>
                    <li>Improved well-being of farmers and their families</li>
                    <li>Promotion of food security</li>
                    <li>Financial inclusion for smallholder farmers</li>
                    <li>Contribution to climate change and waste reduction</li>
                    <li>Decent work and economic growth business model</li>
                  </ul>
                </p>
                <div className="sdgBtn">
                  <a href=".#">Continue</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ImpactPageDetails;
